<template>
    <div class="SelfVideo absolute allw allh border-box overflow-hidden">
        <video :id="RandomId" class="centeredVideo allw allh" autoplay
               controlsList='nofullscreen nodownload noremote footbar'>
            Your browser is too old which doesn't support HTML5 video.
        </video>
        <div v-if="!IsChange" :class="{'mb':mb}" class="absolute maskbox allh allw">
            <div :class="[LangeuageAgent === 'CH' ? 'font14' : 'font12']" class="absolute text-center">
                <p v-for="(item,index) in Tools" :key="index" :class="{'CurrentVideo':CurrentVideo === index}"
                   @click="SetSource(index)" class="pointer">
                    {{Lang[item]}}</p>
            </div>
            <div v-if="TimeCount" class="timeGo absolute">
                <p v-show="CopyTableInfo.betTime === 0" :class="[LangeuageAgent === 'CH' ? 'font20' : 'font12']"
                   class="colorfff text-center allw absolute holdon">{{Lang['QSD']}}<!--请稍等--></p>
                <p v-show="CopyTableInfo.betTime !== 0"
                   class="absolute allw allh flex column items-center content-center">
                    <b :style="{'color':Fillcolor}" class="font40 colorfff mab5">{{CopyTableInfo.betTime}}</b>
                    <span :class="[LangeuageAgent === 'CH' ? 'font20' : 'font12']" class="colorfff">{{Lang['QXZ']}}<!--请下注--></span>
                </p>
                <van-circle v-model="CurrentRate" v-show="CopyTableInfo.betTime !== 0" :rate="Rate" :speed="100"
                            :color="Fillcolor" class="circlebox"/>
            </div>
        </div>
        <div v-if="IsChange" @click="Makedeal(CurrentVideo)"
             class="IsChange absolute position-center font14 flex items-center content-center pointer">
            <span>切换</span>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'index',
        props: {
            'TableInfo': {
                default() {
                    return {gameId: 666, gameStatus: 666};
                }
            },
            'DefaultIndex': {
                default() {
                    return 0;
                }
            },
            'TimeCount': {
                default() {
                    return false;
                }
            },
            'IsChange': {
                default() {
                    return false;
                }
            }
        },
        computed: {...mapState(['DealVideo', 'LangeuageAgent'])},
        data() {
            return {
                RandomId: 'my-player' + parseInt(Math.random() * 100),
                CurrentVideo: 0,
                CurrentVideoUrl: '',
                Initiator: parseInt(Math.random() * 100), //切换视频发起者
                cameraUrl: [],
                mb: true, //是否暂停视频
                Tools: ['SPLZ', 'SPQMBJ', 'SPQJ1', 'SPQJ2', 'SPGB'],//'露珠', '全面背景', '全景1', '全景2', '关闭'
                VideoPlayer: null,
                CurrentRate: 0,
                Rate: 0,
                Fillcolor: '#33E11C', // #33E11C   #E3C31D 25   #FF0000 10
                Timer: null,
                CopyTableInfo: {
                    betTime: 0,
                    cameraUrl: ''
                },
                FirstIn: true,
                BufferedTimer: null, //追帧记时
            };
        },
        created() {
            this.$nextTick(() => {
                let cameraUrl = this.CopyTableInfo.cameraUrl;
                this.CopyTableInfo.cameraUrl = cameraUrl;
            });
        },
        beforeDestroy() {
            if (this.VideoPlayer) {
                this.VideoPlayer.pause();
                this.VideoPlayer.unload();
                this.VideoPlayer.detachMediaElement();
                this.VideoPlayer.destroy();
            }
            clearTimeout(this.Timer);
            clearInterval(this.BufferedTimer);
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    this.CopyTableInfo.cameraUrl = n.cameraUrl; //直接进行视频路径更新 防止后面呢return
                    if (!this.FirstIn) if (n.gameId === o.gameId && n.gameStatus === o.gameStatus && n.betTime === o.betTime) return;
                    this.FirstIn = false;
                    clearInterval(this.Timer);
                    this.CopyTableInfo = this.Clone(n);
                    this.Fillcolor = '#33E11C';
                    this.CurrentRate = 100;
                    if (n.betTime > 0 && n.gameStatus === 1 && this.TimeCount) this.Timer = setInterval(() => {
                        let betTime = this.CopyTableInfo.betTime;
                        if (betTime === 8) this.SetAudio('最后5秒');
                        if (betTime <= 6) this.SetAudio('倒计时铛铛声');
                        this.CopyTableInfo.betTime--;
                        this.Rate = this.CopyTableInfo.betTime / (n.betTime < 40 ? 40 : n.betTime) * 100;
                        this.Fillcolor = betTime <= 10 ? '#FF0000' : betTime < 25 ? '#E3C31D' : '#33E11C';
                        if (this.CopyTableInfo.betTime <= 0) clearTimeout(this.Timer);
                        // if (this.CopyTableInfo.betTime === 0) this.SetStore({Prop: 'betStop', Val: true}); //通知面板停止下注
                    }, 1000);
                    if (n.gameStatus !== 1) {
                        this.CopyTableInfo.betTime = 0;
                    }
                },
                deep: true, immediate: true
            },
            'CopyTableInfo.cameraUrl': {
                handler(n, o) {
                    if (n === o || n === '') return;
                    setTimeout(() => {
                        this.SetVideo(n);
                    }, 0)
                }, deep: true, immediate: true
            },
            'DealVideo': {
                handler(n, o) {
                    let fn = () => {
                        this.SetStore({
                            Prop: 'DealVideo',
                            Val: {index: '', count: 0}
                        });
                    };
                    if (n.index === '' || n.Initiator === this.Initiator || n.index === this.CurrentVideo) return;
                    let OCurrentVideo = this.CurrentVideo;
                    this.SetSource(n.index);
                    if (n.count === 2) {
                        fn();
                    } else {
                        this.Makedeal(OCurrentVideo);
                    }
                }
            }
        },
        methods: {
            SetAudio(url) {
                this.$emit('SetAudio', url);
            },
            SetVideo(url) {
                this.cameraUrl = url.split('||');
                this.CurrentVideo = this.DefaultIndex || 0;
                this.PlayVideo(this.CurrentVideo);
            },
            PlayVideo(index) {
                if (this.flv.isSupported()) {
                    clearInterval(this.BufferedTimer);
                    this.VideoPlayer && this.VideoPlayer.destroy();
                    let videoElement = document.getElementById(this.RandomId);
                    this.VideoPlayer = this.flv.createPlayer({
                        type: 'flv',
                        isLive: true,
                        hasVideo: true,
                        hasAudio: false,
                        url: this.cameraUrl[index],
                        config: {
                            enableWorker: true,
                            enableStashBuffer: false,
                            stashInitialSize: 128,
                        }
                    });
                    this.VideoPlayer.attachMediaElement(videoElement);
                    this.VideoPlayer.load();
                    this.VideoPlayer.play();
                    this.mb = false;
                    this.CurrentVideoUrl = this.cameraUrl[index];
                    this.BufferedTimer = setInterval(() => {
                        let _this = this;
                        try {
                            if (_this.VideoPlayer.buffered.end(0) !== 0 && _this.VideoPlayer.buffered.end(0) - _this.VideoPlayer.currentTime > 2.5) _this.VideoPlayer.currentTime = _this.VideoPlayer.buffered.end(0) - 1;
                        } catch (e) {
                        }
                    }, 5 * 1000);
                }
            },
            SetSource(index) {
                this.mb = true;
                this.VideoPlayer.pause();
                if (index === 4) return;
                if (!this.cameraUrl[index]) {
                    this.Toast('2', '暂无此视频');
                    return;
                }
                this.CurrentVideo = index;
                this.PlayVideo(index);
            },
            Makedeal(index = false) { //互换视频
                let _DealVideo = this.Clone(this.DealVideo);
                _DealVideo.count += 1;
                _DealVideo.index = index;
                _DealVideo.Initiator = this.Initiator;
                this.SetStore({
                    Prop: 'DealVideo',
                    Val: _DealVideo
                });
            }
        }
    }
</script>

<style lang="less">
    .SelfVideo {
        .vjs-big-play-button {
            visibility: hidden; // 隐藏按钮
        }

        .vjs-progress-control {
            visibility: hidden; // 隐藏进度条
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid #af8868;
            box-sizing: border-box;
        }

        &:hover {
            .IsChange {
                opacity: 1;
            }
        }

        video {
            object-fit: fill;
            background-color: #333;
        }

        .maskbox {
            top: 0;
            left: 0;
            z-index: 10;

            &.mb {
                top: 2px;
                right: 2px;
                bottom: 2px;
                left: 2px;
                width: auto;
                height: auto;
                background-color: rgba(22, 22, 22, 1);
            }

            &:hover {
                > div:first-child {
                    display: block;
                }
            }

            > div:first-child {
                top: 50%;
                left: .2rem;
                transform: translate(0, -50%);
                display: none;

                p {
                    padding: 0.08rem 0.1rem;
                    margin: 0.04rem 0;
                    border: solid 1px #a4a17a;
                    border-radius: .06rem;
                    color: #f4efb1;

                    &:hover {
                        background-color: rgba(255, 208, 115, .5);
                    }

                    &.CurrentVideo {
                        background-color: rgba(255, 208, 115, .5);
                    }
                }
            }

            .timeGo {
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: #000000;
                left: .2rem;
                top: .2rem;

                .holdon {
                    bottom: .15rem;
                    padding-top: .55rem;
                    background: url("../../../public/img/Common/saohou.png") no-repeat top center/.5rem .5rem;
                }

                .circlebox.van-circle {
                    width: 1rem !important;
                    height: 1rem !important;
                }
            }
        }

        .IsChange {
            width: .76rem;
            height: .28rem;
            background-color: rgba(255, 208, 155, .6);
            border-radius: 6px;
            border: solid 1px #ffd09b;
            opacity: 0;
            z-index: 10;

            span {
                color: #f4efb1;
            }
        }
    }
</style>
